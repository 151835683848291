<template>
  <!-- <van-dialog class="avatarCropper" v-model="show" title="裁剪头像" @confirm="getCrop" @open="openDialog" @cancel="closeDialog" show-cancel-button> -->
    <div v-show="show" class="avatarCropper">
      <div class="avatar-container">
        <div class="title">裁剪头像</div>
        <!-- 已上传图片 -->
        <div class="avatar-crop">
          <cropper
            :src="fileUrl"
            :stencil-props="{
              aspectRatio: 20/20,
            }"
            stencil-component="circle-stencil"
            ref="cropper"
          />
        </div>
        <div class="footer">
          <div @click="closeDialog" class="item">取消</div>
          <div @click="getCrop" class="color item">确认</div>
        </div>
      </div>
    </div>
  <!-- </van-dialog> -->
</template>

<script>
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
export default {
  components: {
    Cropper,
    CircleStencil
  },
  name: 'avatarCropper',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
      default: ''
    },
    fileName: {
      type: String,
      default: ''
    },
    fileType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false,
      fileUrl: '',
    }
  },
  watch: {
    dialogVisible(){
      this.show = this.dialogVisible
      if(this.dialogVisible){
        this.fileUrl = this.img
        // this.$nextTick(() => {
        //   this.$refs.cropper.refresh()
        // })
      }
    }
  },
  methods: {
    // 获取截图信息
    getCrop() {
      const { canvas } = this.$refs.cropper.getResult()
      // 获取截图的 base64 数据
      // let dataURL = canvas.toDataURL();

      // 获取截图的 blob 数据
      canvas.toBlob((data) => {
        let files = new window.File(
          [data],
          this.fileName,
          {  type: this.fileType }
        );
        this.$emit('closeAvatarDialog', files)
      })
    },
    // 关闭弹框
    closeDialog() {
      this.$emit('closeAvatarDialog', false)
    }
  }
}
</script>

<style lang="less" scoped>
// .avatarCropper{
//   color: #000;
//   /deep/.van-dialog__header{
//     padding-top: 15px;
//     margin-bottom: 10px;
//   }
// }
// .dialog-footer {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   font-size: 14px;
//   .reupload {
//     color: #409eff;
//     cursor: pointer;
//   }
// }
.avatarCropper{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .avatar-container {
    color: #000;
    font-size: 16px;
    width: 320px;
    min-height: 330px;
    background-color: #f0f2f5;
    border-radius: 10px;
    .title{
      text-align: center;
      padding: 10px 0;
    }
    .avatar-crop {
      width: 100%;
      .crop-box {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;
      }
      /deep/.vue-advanced-cropper{
        min-height: 330px;
      }
      /deep/.vue-advanced-cropper__background{
        min-height: 300px;
      }
    }
    .footer{
      display: flex;
      .item{
        text-align: center;
        width: 50%;
        padding: 8px 0;
      }
      .color{
        color: #ee0a24;
      }
    }
  }
}
</style>