<template>
  <div class="myProfile">
    <watch-head :title="$t('我的简历')"
                :img="require('@/assets/bracelet_img/61.png')" />
    <div class="main">
      <div class="avatar">
        <img class="img"
             :src="userInfo.avatar"
             alt="">
        <img class="edit pointer"
              @click="nameDialog = true"
             src="@/assets/bracelet_img/62.png"
             alt="">
        <!-- <div class="grade">{{userInfo.level_no}}</div> -->
      </div>
      <!-- <van-uploader class="avatar"
                    :after-read="afterRead"
                    :max-count="1"
                    :deletable="false"
                    :preview-image="false"
                    :preview-full-image="false"
                    :multiple="false"
                    accept="image/*">
        <img class="img"
             :src="userInfo.avatar"
             alt="">
        <img class="edit"
             src="@/assets/bracelet_img/62.png"
             alt="">
      </van-uploader> -->
      <div class="name"
           :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{userInfo.nickname || ''}}</div>
      <div class="id"
           :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('我的评分')}}: {{userInfo.social_score || 0}}</div>
      <div class="id"
           :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('地址')}}: {{
                    userInfo.username.slice(0, 8) +
                    "..." +
                    userInfo.username.slice(-6)
                }}
        <img @click="copy"
             :data-clipboard-text="userInfo.username"
             class="copyAddress pointer"
             src="@/assets/bracelet_img/74.png"
             alt="">
      </div>
      <div class="balance"
           :class="{'vn-italic': $i18n.locale == 'vn'}">- {{$t('我的钱包余额')}} -</div>
      <div class="box">
        <div class="card">
          <img src="@/assets/bracelet_img/63.png"
               alt="">
          <div class="name">SOLARIX</div>
          <div class="addr">
            <div class="tiao"></div>
            <div class="text">{{solarixAddress}}</div>
            <div class="tiao"></div>
          </div>
          <div class="number">{{mySolarixBalance || 0}}</div>
        </div>
        <div class="card">
          <img src="@/assets/bracelet_img/65.png"
               alt="">
          <div class="name">IONZ</div>
          <div class="addr">
            <div class="tiao"></div>
            <div class="text">{{ionzAddress}}</div>
            <div class="tiao"></div>
          </div>
          <div class="number">{{myIonzBalance || 0}}</div>
        </div>
        <div class="card">
          <img src="@/assets/bracelet_img/64.png"
               alt="">
          <div class="name">BITNEY</div>
          <div class="addr">
            <div class="tiao"></div>
            <div class="text">{{bitneyAddress}}</div>
            <div class="tiao"></div>
          </div>
          <div class="number">{{myBitneyBalance || 0}}</div>
        </div>
      </div>

      <!-- <van-field class="word"
                 :class="{'vn-italic': $i18n.locale == 'vn'}"
                 type="password"
                 v-model="password"
                 :placeholder="$t('请输入密码')"
                 label-width="0"
                 input-align="center">
        <template #right-icon>
          <img @click="setEncry"
               class="xia"
               src="@/assets/bracelet_img/68.png"
               alt="">
        </template>
      </van-field> -->
      <van-button :class="{'vn-italic': $i18n.locale == 'vn'}"
                  @click="openMeta"
                  style="border:0;"
                  class="language mar_top_20">
        <div class="text"
             :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{ userInfo.is_active == 0 ? $t('未激活'):$t('已激活')}}</div>
      </van-button>

      <van-button style="border:0;"
                  @click="setPwdShow = true"
                  class="language mar_top_20 copybtn">
        <div class="text"
             :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('设置密码')}}</div>
      </van-button>

      <van-button style="border:0;"
                  :data-clipboard-text="userInfo ? webname + '?invite=' + userInfo.invite + '/' : ''"
                  @click="copyUrl"
                  class="language mar_top_20 copybtn">
        <div class="text"
             :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('邀请链接')}}</div>
      </van-button>
      <!-- <div class="language mar_top_20">
        <img src="@/assets/bracelet_img/66.png"
             alt="">
        <div class="text">{{$t('当地语言')}}</div>
      </div> -->
      <!-- <div @click="goTo('/ratingList')"
           class="language mar_top_20">
        <div class="text">{{$t('评分列表')}}</div>
      </div> -->
      <van-button :loading="regainLoading"
                  :loading-text="$t('购买中...')"
                  :class="{'vn-italic': $i18n.locale == 'vn'}"
                  @click="buyIntegral"
                  style="border:0;"
                  class="language mar_top_20">
        <div class="text"
             :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('购买评分')}}+10</div>
      </van-button>
    </div>

    <watch-foot />

    <van-dialog v-model="setPwdShow"
                style="color:#000;"
                :title="$t('设置密码')"
                :beforeClose="setEncry"
                show-cancel-button>
      <van-field v-model="password"
                 type="password"
                 :label="$t('密码')"
                 :placeholder="$t('请输入密码')" />
      <van-field v-model="againpassword"
                 type="password"
                 :label="$t('再次确认')"
                 :placeholder="$t('请再次输入密码')" />
    </van-dialog>

    <van-dialog v-model="nameDialog"
                style="color:#000;"
                :title="$t('设置个人信息')"
                :beforeClose="setName"
                show-cancel-button>
      <van-uploader class="avatar"
                    :after-read="afterRead"
                    :max-count="1"
                    :deletable="false"
                    :preview-image="false"
                    :preview-full-image="false"
                    :multiple="false"
                    accept="image/*">
        <img class="img"
             :src="userAvatar"
             alt="">
      </van-uploader>
      <van-field v-model="nickname"
                 :label="$t('昵称')"
                 :placeholder="$t('请输入昵称')" />
    </van-dialog>

    <avatar-cropper :dialogVisible="dialogVisible" :img="img" :fileName="fileName" :fileType="fileType" @closeAvatarDialog="closeAvatarDialog" />
  </div>
</template>

<script>
import watchHead from '@/components/watchHead/index.vue'
import watchFoot from '@/components/watchFoot/index.vue'
import avatarCropper from './components/avatarCropper.vue'
import { BuyIntegralApi, mainConfig, userInfo, setWatchPassword, getEncryCode, uploadApi, updateInfo } from '@/api/api'
import { mapState } from 'vuex'
import { erc20Abi } from '@/utils/abi'
import CryptoJS from 'crypto-js'

export default {
  components: {
    watchHead,
    watchFoot,
    avatarCropper
  },
  data () {
    return {
      isSetEncry: false,
      password: '',
      againpassword: '',
      encryKey: '',

      regainLoading: false,
      webname: 'http://metanebulas2u.com:817/t/',
      userInfo: {
        username: '000000000000000000000',
        webname: '',
        invite: '',
        avatar: '',
      },
      myIonzBalance: '',
      mySolarixBalance: '',
      myBitneyBalance: '',
      setPwdShow: false,
      
      dialogVisible: false,
      img: '',
      fileName: '',
      fileType: '',

      nameDialog: false,
      nickname: '',
      avatarUrl: '',
      userAvatar: '',
    }
  },
  created () {
    this.waitForGlobal()
  },
  computed: {
    ...mapState([
      'web3', 'ionzContract', 'ionzAccuracy', 'solarixContract', 'solarixAccuracy', 'bitneyContract', 'bitneyAccuracy', 'ionzAddress', 'solarixAddress', 'bitneyAddress',
    ]),
  },
  methods: {
    encryptByDES (message, key) {
      var keyHex = CryptoJS.enc.Utf8.parse(key);
      var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.toString();
    },
    /* 跳转去1.0首页 */
    openMeta(){
     if(this.userInfo.is_active == 0){
          window.open("https://api.metanebulas.com/m/#/")
     }else{
          this.$toast(this.$t('已激活'));
     }
     
    },
    setName(action, done){
      if (action == 'confirm') {
        let parmas = { nickname: this.nickname, avatar: this.avatarUrl }
        if (!this.nickname) {
          parmas.nickname = this.userInfo.nickname
        }
        if(!this.avatarUrl){
          parmas.avatar = this.userInfo.avatar
        }
        if(!this.avatarUrl && !this.nickname){
          done()
          return
        }
        updateInfo(parmas).then(res => {
          if (res.code == 0) {
            this.getUserInfo()
            done()
            this.nickname = ''
            this.avatarUrl = ''
          } else {
            done(false)
          }
          this.$toast(res.msg);
        })
      } else {
        done()
      }
    },
    setEncry (action, done) {
      if (action == 'confirm') {
        if (!this.password) {
          this.$toast(this.$t('密码不能为空'));
          done(false)
          return
        }
        if (this.password !== this.againpassword) {
          this.$toast(this.$t('两次输入的密码不一致'));
          done(false)
          return
        }
        if (this.isSetEncry) {
          done(false)
          return
        }
        this.isSetEncry = true
        setWatchPassword({
          password: this.encryptByDES(this.password, this.encryKey)
        }).then(res => {
          if (res.code == 0) {
            done()
            this.password = ''
            this.againpassword = ''
          } else {
            done(false)
          }
          this.$toast(res.msg);
          this.isSetEncry = false
        })
      } else {
        done()
        this.password = ''
        this.againpassword = ''
      }

    },
    getEncryKey () {
      getEncryCode().then(res => {
        if (res.code == 0) {
          this.encryKey = res.data.code
        }
      })
    },

    //复制按钮
    copyUrl () {
      var clipboard = new this.$Clipboard(".copybtn");
      clipboard.on("success", (e) => {
        this.$toast(this.$t('复制成功'));
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$toast(this.$t('不支持复制'));
        // 释放内存
        clipboard.destroy();
      });
    },
    goTo (url) {
      this.$router.push({
        path: url
      })
    },
    getConfig () {
      mainConfig().then(res => {
        if (res.code == 0) {
          this.webname = res.data.webname
        }
      })
    },
    //上传裁剪后的图片
    closeAvatarDialog(data){
      if(data){
        let formData = new FormData();
        formData.append("file", data);
        uploadApi(formData).then(res => {
          if (res.code == 0) {
            // this.setInfo(res.data.uris[0])
            this.avatarUrl = res.data.uris[0]
            this.userAvatar = res.data.uris[0]
            this.nameDialog = true
          }
        })
      }else{
        this.nameDialog = true
      }
      this.dialogVisible = false
    },
    /* 上传 */
    afterRead (file) {
      this.img = file.content
      this.fileName = file.file.name
      this.fileType = file.file.type
      this.dialogVisible = true
      this.nameDialog = false
    },
    /* 更新用户信息 */
    setInfo (url) {
      updateInfo({ avatar: url }).then(res => {
        console.log(res)
        if (res.code == 0) {
          this.getUserInfo()
        }
      })
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo')) {
        window.clearTimeout(this.timer);
        this.getUserInfo()
        this.getBalance()
        this.getEncryKey()
        this.getConfig()
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    copy () {
      var clipboard = new this.$Clipboard(".copyAddress");
      clipboard.on("success", (e) => {
        this.$toast(this.$t('复制成功'));
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$toast(this.$t('不支持复制'));
        // 释放内存
        clipboard.destroy();
      });
    },
    /* 获取钱包余额 */
    getBalance () {
      this.ionzContract.methods.balanceOf(localStorage.getItem('defaultAddress')).call().then(res => {
        // this.myIonzBalance = res / Math.pow(10, this.ionzAccuracy)
        this.myIonzBalance = Math.round((res / Math.pow(10, this.ionzAccuracy)) * 100) / 100
      })
      this.solarixContract.methods.balanceOf(localStorage.getItem('defaultAddress')).call().then(res => {
        // this.mySolarixBalance = res / Math.pow(10, this.solarixAccuracy)
        this.mySolarixBalance = Math.round((res / Math.pow(10, this.solarixAccuracy)) * 100) / 100
      })
      this.bitneyContract.methods.balanceOf(localStorage.getItem('defaultAddress')).call().then(res => {
        // this.myBitneyBalance = res / Math.pow(10, this.bitneyAccuracy)
        this.myBitneyBalance = Math.round((res / Math.pow(10, this.bitneyAccuracy)) * 100) / 100
      })
    },
    /* 获取用户信息 */
    getUserInfo () {
      userInfo({}, localStorage.getItem('Token')).then(res => {
        this.userInfo = res.data
        this.userAvatar = res.data.avatar
      })
    },
    /* 购买评分 */
    buyIntegral () {
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('购买中...'), //购买中...
      });
      this.regainLoading = true
      mainConfig().then(res => {
        if (res.code == 0) {
          BuyIntegralApi().then(resu => {
            if (resu.code == 0) {
              //     let value = new this.$Bignumber(resu.data.val * Math.pow(10, res.data.score_accuracy));
              let tokenContract = new this.web3.eth.Contract(erc20Abi, res.data.score_token);
              tokenContract.methods.transfer(res.data.score_address, this.web3.utils.fromDecimal(resu.data.money)).send({ from: localStorage.getItem('defaultAddress') })
                .then((resuq) => {
                  console.log('转账成功了吗-----', resuq);
                  this.regainLoading = false
                  toast.clear();
                  this.$toast(this.$t('购买成功,等待交易查询中'));
                })
                .catch((err) => {
                  console.log('转账失败了吗-----', err);
                  this.regainLoading = false
                  toast.clear();
                  this.$toast(this.$t('购买失败'));
                })
            } else {
              toast.clear();
              this.regainLoading = false
              this.$toast(resu.msg);
            }
          })
        } else {
          this.regainLoading = false
          toast.clear();
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.myProfile {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  .main {
    margin-top: 27px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar {
      border-radius: 50%;
      width: 132px;
      height: 132px;
      position: relative;
      .img {
        width: 132px;
        height: 132px;
        border-radius: 50%;
        object-fit: cover;
        background-color: #2b2b2b;
        border: 5px solid #2b2b2b;
      }
      .edit {
        position: absolute;
        right: 0px;
        top: 5px;
        width: 21px;
        height: 21px;
      }
      .grade {
        position: absolute;
        right: -30px;
        bottom: -5px;
        width: 61px;
        height: 61px;
        border-radius: 50%;
        background-color: #2b2b2b;
        font-family: 'AeroMaticsDisplayBoldItalic';
        font-size: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .name {
      font-family: 'AeroMaticsBoldItalic';
      font-size: 28px;
      margin: 10px 0 0;
    }
    .id {
      font-family: 'AeroMaticsBold';
      font-size: 17px;
      color: #f4f2f4;
      margin-top: -5px;
      img {
        width: 22px;
        height: 22px;
        margin-left: 5px;
        vertical-align: middle;
      }
    }
    .balance {
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 25px;
      margin: 25px 0 10px;
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      .card {
        padding: 9px 0;
        width: 32%;
        // height: 88px;
        background-color: #2b2b2b;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 25px;
          height: 25px;
        }
        .name {
          font-family: 'AeroMaticsBoldItalic';
          font-size: 14px;
          margin-top: 4px;
        }
        .addr {
          width: 100%;
          display: flex;
          align-items: center;
          margin: -1px 0 7px;
          .tiao {
            width: 7px;
            min-width: 7px;
            height: 8px;
            background-color: #a7f264;
          }
          .text {
            font-family: 'AeroMaticsBold';
            color: #f4f2f4;
            width: 100%;
            font-size: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            margin: 0 7px;
          }
        }
        .number {
          width: 90%;
          text-align: center;
          background-color: #ffffff;
          border-radius: 12px;
          padding: 2.5px 0;
          font-family: 'AeroMaticsDisplayItalic';
          font-size: 12px;
          color: #2b2b2b;
        }
      }
    }
    .van-field {
      background-color: #ffffff;
      border-radius: 18.75px;
      /deep/.van-field__right-icon {
        display: flex;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      /deep/.xia {
        width: 26.25px;
        height: 26.25px;
      }
    }
    .word {
      /deep/.van-field__control {
        font-family: 'AeroMaticsDisplayItalic';
        font-size: 18.75px;
        color: #2b2b2b;
      }
      /* WebKit, Blink, Edge */
      /deep/.van-field__control::-webkit-input-placeholder {
        font-size: 18.75px;
      }
      /* Mozilla Firefox 4 to 18 */
      /deep/.van-field__control:-moz-placeholder {
        font-size: 18.75px;
      }
      /* Mozilla Firefox 19+ */
      /deep/.van-field__control::-moz-placeholder {
        font-size: 18.75px;
      }
      /* Internet Explorer 10-11 */
      /deep/.van-field__control:-ms-input-placeholder {
        font-size: 18.75px;
      }
      /* Microsoft Edge */
      /deep/.van-field__control::-ms-input-placeholder {
        font-size: 18.75px;
      }
    }
    .language {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #a7f264;
      border-radius: 5px;
      position: relative;
      padding: 5px 0;
      img {
        width: 31px;
        height: 31px;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      .text {
        font-family: 'REEJI-PinboGB-Flash';
        font-size: 22px;
        color: #2b2b2b;
      }
    }
    .mar_top_20 {
      margin-top: 20px;
    }
  }
  .van-dialog{
    width: 300px;
    /deep/.van-dialog__content{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    /deep/.avatar {
      margin: 15px 0 10px;
      border-radius: 50%;
      // width: 70px;
      // height: 70px;
      position: relative;
      .img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
        background-color: #2b2b2b;
        border: 5px solid #2b2b2b;
      }
      .edit {
        position: absolute;
        right: 0px;
        top: 5px;
        width: 21px;
        height: 21px;
      }
      .grade {
        position: absolute;
        right: -30px;
        bottom: -5px;
        width: 61px;
        height: 61px;
        border-radius: 50%;
        background-color: #2b2b2b;
        font-family: 'AeroMaticsDisplayBoldItalic';
        font-size: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>